import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from '../styles/Homepage.module.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div>
      <h1 className={styles.title}>Makers Create Studios</h1>
      <h3 className={styles.subtitle}>Open for hire</h3>

      <a href="mailto:nickjnish@gmail.com" className={styles.contact}>
        <button className={styles.contactBtn}>Contact Us</button>
      </a>
    </div>
  </Layout>
)

export default IndexPage
